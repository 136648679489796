/** @jsx jsx */
import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useComparison, usePathnameSearchOptionList, useQueryParam } from '@modules';
import { jsx, Flex, Grid } from '@themed';
import { Institution, ChildrenProps, CountryCalculation } from '@types';
import { ListItem, SectionWithContent, SegmentedControl } from '@core';
import { PacmanLoader } from '../finnoscore-data/PacmanLoader';
import { DefaultErrorMessage } from '../finnoscore-data/DefaultErrorMessage';
import Profile from '../finnoscore-data/Profile';
import ReportAdvertLink from '../finnoscore-data/ReportAdvertLink';
import Charts from './Charts';
import { ComparisonDropDownContextProvider } from './ComparisonDropDownContext';
import { ComparisonDropDownControl } from './ComparisonDropDownControl';
import { styles } from './FinnoscoreComparisonSelector.styles';

const FSCompareTabSelector = () => {
  const [countryCode] = useQueryParam('countryCode');
  const { pathname, search } = useLocation();
  const chartTypes = usePathnameSearchOptionList(pathname, `${search}${search ? '&' : '?'}countryCode=${countryCode}`, `${pathname}${search}`);
  return (
    <SegmentedControl id='chartType' defaultIndex={1} sx={styles.tabSelector} items={chartTypes} onChange={({ value }) => navigate(`${value}`)} />
  );
};

export interface FinnoscoreComparisonSectionData {
  countries?: ListItem[];
  institutions?: Institution[];
  countryCalculations?: CountryCalculation[];
}

export interface FinnosoreComparisonSectionMeta {
  error?: Error | null;
  isLoading?: boolean;
}

type FinnosoreComparisonSectionProps = FinnoscoreComparisonSectionData & FinnosoreComparisonSectionMeta;

export const FinnoscoreComparisonChart = ({ countries, institutions, countryCalculations }: FinnoscoreComparisonSectionData) => {
  const { comparison, setA, setB, error } = useComparison(institutions, countryCalculations);

  return (
    <React.Fragment>
      <ComparisonDropDownContextProvider>
        <FSCompareTabSelector />

        <ComparisonDropDownControl countries={countries} institutions={institutions} comparison={comparison} setA={setA} setB={setB} />
      </ComparisonDropDownContextProvider>

      <Flex id='content' data-cy='compare-content' variant='flex.colLeft' sx={styles.chart}>
        {comparison && !error && <Charts comparison={comparison} />}
        {error && <DefaultErrorMessage sx={styles.error} error={error} />}
      </Flex>
    </React.Fragment>
  );
};

export const FinnoscoreComparisonSection = ({ error, isLoading, ...props }: FinnosoreComparisonSectionProps) => (
  <SectionWithContent sx={styles.section} contentComponent={({ children }: ChildrenProps) => <Flex sx={styles.page}>{children}</Flex>}>
    <Grid sx={styles.content}>
      <ReportAdvertLink id='report' />

      <Profile id='contact' />

      {isLoading && <PacmanLoader id='loader' />}

      {error && <DefaultErrorMessage id='loader' sx={styles.error} error={error} />}

      {!error && !isLoading && <FinnoscoreComparisonChart {...props} />}
    </Grid>
  </SectionWithContent>
);
