import { CountryCalculationFromApi, FsType, InstitutionFromApi, Translation } from '@types';
import { graphqlRequest } from '../graphqlRequest';
import { getTranslationsQuery } from '../queries';
import { applyAllStrapiQueryParams, excludedExtraFlagsQueryTemplate, strapiQueryBuilder } from './strapiQueryBuilder';

interface FsPeriod {
  id: number;
  date: string;
  institutions: InstitutionFromApi[];
  countryCalculations: CountryCalculationFromApi[];
}

export interface InstitutionQueryResult {
  currentPeriod: FsPeriod[];
  comparePeriod: FsPeriod[];
  translations: Translation[];
}

export interface FetchPeriodsProps {
  fsType: FsType;
  fsPeriod?: number;
  date?: string;
  compareDate?: string;
  countryCode?: string;
}
export const getDatesInOrder = (date?: string, compareDate?: string) => {
  const dates = [date, compareDate || date];
  return dates;
};

// NOTE: pulls both previews (drafts and live), and used build time (live only)
export const fetchPeriods = async ({ fsType, date, compareDate: compareDateRequested }: FetchPeriodsProps) => {
  const [currentDate, compareDate] = getDatesInOrder(date, compareDateRequested);
  // applyStrapiQueryParam('countryCode', countryCode), // currently we shall not filter for countryCode, as we don't refresh data from API on changing countryCode on the UI
  const where = strapiQueryBuilder('', applyAllStrapiQueryParams({ fsType, date: currentDate }));
  const extraFlagsLive = compareDate ? undefined : excludedExtraFlagsQueryTemplate;
  const whereLive = strapiQueryBuilder('', applyAllStrapiQueryParams({ fsType, date: compareDate }), extraFlagsLive);
  const filterWithoutWeightRecord = `(where: {fsType:"${fsType}"})`;

  const query = `{
  currentPeriod:fsPeriods(sort: "date:desc"${where}, limit: 1) {
    id
    date
    institutions: fs_institutions${filterWithoutWeightRecord} {
      id
      fsType
      name
      logo {
        url
        alternativeText
      }
      total
      rank
      dimensions
      country: fs_country {
          countryCode
        flag {
          url
          alternativeText
        }
      }
    }
    countryCalculations: fs_country_calculations {
      id
      fsType
      total
      type
      dimensions
      country: fs_country {
        countryCode
        flag {
          url
          alternativeText
        }
      }
    }
  }
  comparePeriod:fsPeriods(sort: "date:desc"${whereLive}, limit: 2) {
      id
      date
      institutions: fs_institutions${filterWithoutWeightRecord} {
        id
        fsType
        name
        logo {
            url
            alternativeText
        }
        total
        rank
        dimensions
        country: fs_country {
          countryCode
          flag {
              url
            alternativeText
          }
        }
    }
    countryCalculations: fs_country_calculations {
      id
      fsType
      total
      type
      dimensions
      country: fs_country {
        countryCode
        flag {
            url
          alternativeText
        }
      }
    }
  }
  ${getTranslationsQuery()}
}`;

  try {
    const response = await graphqlRequest<InstitutionQueryResult>(query);

    const comparePeriod = (() => {
      if (response.comparePeriod[0].date === response.currentPeriod[0].date && !compareDateRequested && response.comparePeriod[1])
        return response.comparePeriod[1]; // we choose the 2nd period, if that is matches with, and the dates were not set strictly
      if (response.comparePeriod[0]) return response.comparePeriod[0];
      return response.currentPeriod[0]; // default to currentPeriod if there is nothing to find
    })();
    console.log('currentPeriod for ', fsType, ': #', response.currentPeriod[0].id, ',', response.currentPeriod[0].date);
    console.log('comparePeriod for ', fsType, ': #', comparePeriod.id, ',', comparePeriod.date);
    return {
      lastestPeriod: {
        id: response.currentPeriod[0].id,
        date: response.currentPeriod[0].date,
      },
      previousPeriod: {
        id: comparePeriod.id,
        date: comparePeriod.date,
      },
      latestPeriodInstitutions: response.currentPeriod[0].institutions,
      previousPeriodInstitutions: comparePeriod.institutions,
      latestCountryCalculations: response.currentPeriod[0].countryCalculations || [],
      translations: response.translations,
    };
  } catch (error) {
    throw new Error(`Fetching institutions failed: ${error}`);
  }
};
