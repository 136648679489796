/** @jsx jsx */
import React from 'react';
import { jsx, Flex, Grid, useIsTablet } from '@themed';
import { SectionWithContent } from '@core';
import { ALL_COUNTRIES, SelectedCountryContextProvider, useQueryParam } from '@modules';
import { Institution, ChildrenProps, Country, FsType } from '@types';
import { BankPress, LandingPress } from '../Press';
import { finnoscoreDataStyles as styles } from '../finnoscore-data/index.styles';
import { PacmanLoader } from '../finnoscore-data/PacmanLoader';
import { DefaultErrorMessage } from '../finnoscore-data/DefaultErrorMessage';
import ReportAdvertLink, { ReportButton } from '../finnoscore-data/ReportAdvertLink';
import FinnoscoreList from './FinnoscoreList';
import { CountrySelector } from './CountrySelector';
import FSTabSelector from './FSTabSelector';

export interface FinnoscoreListSectionData {
  countries?: Country[];
  institutions?: Institution[];
}

export interface FinnoscoreListSectionMeta {
  isLoading?: boolean;
  error?: Error | null;
}

interface FinnoscoreListSectionProps extends FinnoscoreListSectionData, FinnoscoreListSectionMeta {
  fsType?: FsType;
}

export const FinnoscoreListSection = ({ isLoading, error, countries, institutions, fsType }: FinnoscoreListSectionProps) => {
  const [countryCode] = useQueryParam('countryCode');
  const defaultCountry = React.useMemo(() => countries?.find((c) => c.countryCode === (countryCode || ALL_COUNTRIES)), [countries, countryCode]);
  const isTablet = useIsTablet();

  return (
    <SectionWithContent sx={styles.section} contentComponent={({ children }: ChildrenProps) => <Flex sx={styles.page}>{children}</Flex>}>
      <Grid sx={styles.content}>
        {isLoading && <PacmanLoader id='loader' />}

        <ReportAdvertLink id='report' />

        {/* <Profile id="contact" /> */}

        {isTablet ? <LandingPress id='press' fsType={fsType} /> : <BankPress id='press' fsType={fsType} />}

        {countries && defaultCountry && institutions && !error && !isLoading && (
          <SelectedCountryContextProvider defaultValue={defaultCountry}>
            <FSTabSelector fsType={fsType} id='chartType' institutions={institutions} sx={styles.tabSelector} />

            <Flex id='control' sx={styles.control}>
              <ReportButton />
              <CountrySelector countries={countries} />
            </Flex>

            <Flex id='content' variant='flex.colCenter'>
              <FinnoscoreList institutions={institutions} />
            </Flex>
          </SelectedCountryContextProvider>
        )}

        {error && <DefaultErrorMessage id='loader' sx={styles.error} error={error} />}
      </Grid>
    </SectionWithContent>
  );
};
