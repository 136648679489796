import { SourceNodesArgs } from 'gatsby';
import { FS_TYPES } from '../../types';
import {
  authenticate,
  fetchAuthors,
  fetchArticles,
  fetchGermanArticles,
  fetchTags,
  fetchPageMeta,
  fetchCountries,
  fetchCaseStudies,
  fetchGermanCaseStudies,
  fetchPeriods,
} from '../networking';
import {
  formatArticles,
  formatAuthors,
  formatTags,
  formatPageMeta,
  formatCaseStudies,
  formatCountries,
  formatInstitutions,
  formatCountryCalculations,
} from '../formatting';
import { getStrapiRoutes } from '../networking/routes';

const ARTICLE_TYPE = 'Article';
const AUTHOR_TYPE = 'Author';
const TAG_TYPE = 'Tag';
const PAGE_META_TYPE = 'PageMeta';
const CASE_STUDY_TYPE = 'CaseStudy';
const COUNTRY_TYPE = 'Country';
const INSTITUTION_TYPE = 'Institution';
const COUNTRY_CALCULATION_TYPE = 'CountryCalculation';

const authenticateCms = async () => {
  const { GATSBY_STRAPI_API_URL: strapiUrl, STRAPI_USERNAME: strapiUsername, STRAPI_PASSWORD: strapiPassword } = process.env;

  if (!strapiUrl) throw new Error('ENV VAR: STRAPI_API_URL is not set. It should be the backend url like: finnoconsult.at:3457');
  if (!strapiUsername) throw new Error('ENV VAR: STRAPI_USERNAME is not set. It should be the username used to download data via graphql');
  if (!strapiPassword) throw new Error('ENV VAR: STRAPI_PASSWORD is not set. It should be the password for the given user');

  await authenticate(
    {
      identifier: strapiUsername,
      password: strapiPassword,
    },
    getStrapiRoutes().cms.authenticate
  );
};

function createNodeFromFormattedResults<Resource>(args: SourceNodesArgs, resource: Resource, id: string | number, type: string) {
  const { createNode } = args.actions;
  const { createContentDigest } = args;

  createNode({
    ...resource,
    id: String(id),
    parent: null,
    children: [],
    internal: {
      type,
      content: JSON.stringify(resource),
      // have to use object, because gatsby wants it, but we only cast here, to minimize usage
      // eslint-disable-next-line @typescript-eslint/ban-types
      contentDigest: createContentDigest(resource as unknown as string | object),
    },
  });
}

const createArticleNodes = async (args: SourceNodesArgs) => {
  const fetchResult = await Promise.all([
    fetchGermanArticles(),
    fetchArticles('englishArticles'),
    fetchArticles('frenchArticles'),
    fetchAuthors(),
    fetchTags(),
  ]);

  const [germanArticlesFromApi, englishArticlesFromApi, frenchArticlesFromApi, authorsFromApi, tagsFromApi] = fetchResult;

  const germanArticles = formatArticles(germanArticlesFromApi, 'de');
  const englishArticles = formatArticles(englishArticlesFromApi, 'en', germanArticlesFromApi);
  const frenchArticles = formatArticles(frenchArticlesFromApi, 'fr', germanArticlesFromApi);

  const germanAuthors = formatAuthors(authorsFromApi, 'de', germanArticles);
  const englishAuthors = formatAuthors(authorsFromApi, 'en', englishArticles);
  const frenchAuthors = formatAuthors(authorsFromApi, 'fr', frenchArticles);

  const germanTags = formatTags(tagsFromApi, 'de', germanArticles);
  const englishTags = formatTags(tagsFromApi, 'en', englishArticles);
  const frenchTags = formatTags(tagsFromApi, 'fr', frenchArticles);

  germanArticles.map((article) => createNodeFromFormattedResults(args, article, article.slug, ARTICLE_TYPE));
  englishArticles.map((article) => createNodeFromFormattedResults(args, article, article.slug, ARTICLE_TYPE));
  frenchArticles.map((article) => createNodeFromFormattedResults(args, article, article.slug, ARTICLE_TYPE));

  germanAuthors.map((author) => createNodeFromFormattedResults(args, author, author.slug, AUTHOR_TYPE));
  englishAuthors.map((author) => createNodeFromFormattedResults(args, author, author.slug, AUTHOR_TYPE));
  frenchAuthors.map((author) => createNodeFromFormattedResults(args, author, author.slug, AUTHOR_TYPE));

  germanTags.map((tag) => createNodeFromFormattedResults(args, tag, tag.slug, TAG_TYPE));
  englishTags.map((tag) => createNodeFromFormattedResults(args, tag, tag.slug, TAG_TYPE));
  frenchTags.map((tag) => createNodeFromFormattedResults(args, tag, tag.slug, TAG_TYPE));
};

const createCaseStudyNodes = async (args: SourceNodesArgs) => {
  const fetchResult = await Promise.all([fetchGermanCaseStudies(), fetchCaseStudies('englishCaseStudies'), fetchCaseStudies('frenchCaseStudies')]);

  const [germanCaseStudiesFromApi, englishCaseStudiesFromApi, frenchCaseStudiesFromApi] = fetchResult;

  const germanCaseStudies = formatCaseStudies(germanCaseStudiesFromApi, 'de');
  const englishCaseStudies = formatCaseStudies(englishCaseStudiesFromApi, 'en', germanCaseStudiesFromApi);
  const frenchCaseStudies = formatCaseStudies(frenchCaseStudiesFromApi, 'fr', germanCaseStudiesFromApi);

  germanCaseStudies.map((caseStudy) => createNodeFromFormattedResults(args, caseStudy, caseStudy.slug, CASE_STUDY_TYPE));
  englishCaseStudies.map((caseStudy) => createNodeFromFormattedResults(args, caseStudy, caseStudy.slug, CASE_STUDY_TYPE));
  frenchCaseStudies.map((caseStudy) => createNodeFromFormattedResults(args, caseStudy, caseStudy.slug, CASE_STUDY_TYPE));
};

const createPageMetaNodes = async (args: SourceNodesArgs) => {
  const fetchResult = await Promise.all([fetchPageMeta()]);

  const [pageMetaFromApi] = fetchResult;

  const pageMeta = formatPageMeta(pageMetaFromApi);

  pageMeta.map((meta) => createNodeFromFormattedResults(args, meta, meta.id, PAGE_META_TYPE));
};

const createFinnoscoreNodes = async (args: SourceNodesArgs) => {
  const countriesWithTranslations = await fetchCountries();
  const periodsWithTranslations = await Promise.all(FS_TYPES.map((fsType) => fetchPeriods({ fsType })));

  const countries = formatCountries(countriesWithTranslations.countries, countriesWithTranslations.translations);
  countries.forEach((country) => createNodeFromFormattedResults(args, country, `${country.countryCode}-${country.language}`, COUNTRY_TYPE));

  FS_TYPES.forEach((fsType, index) => {
    const period = periodsWithTranslations[index];
    if (!period || !period.latestPeriodInstitutions || period.latestPeriodInstitutions.length === 0) return;

    const latestLivePeriodInstitutions = formatInstitutions(period.latestPeriodInstitutions, period.previousPeriodInstitutions, period.translations);
    const latestCountryCalculations = formatCountryCalculations(period.latestCountryCalculations, period.translations);

    latestLivePeriodInstitutions.forEach((bank) =>
      createNodeFromFormattedResults(args, bank, bank.language ? `${bank.language}-${bank.id}-${fsType}` : `${bank.id}-${fsType}`, INSTITUTION_TYPE)
    );
    latestCountryCalculations.forEach((countryCalculation) =>
      createNodeFromFormattedResults(
        args,
        countryCalculation,
        countryCalculation.language ? `${countryCalculation.language}-${countryCalculation.id}-${fsType}` : `${countryCalculation.id}-${fsType}`,
        COUNTRY_CALCULATION_TYPE
      )
    );
  });
};

export const createAllFinnoCMSNodes = async (args: SourceNodesArgs) => {
  await authenticateCms();

  await Promise.all([
    await createArticleNodes(args),
    await createCaseStudyNodes(args),
    await createPageMetaNodes(args),
    await createFinnoscoreNodes(args),
  ]);
};
