/** @jsx jsx */
import React from 'react';
import { jsx } from '@themed';
import { Layout, StaticSEO } from '@core';
import { NewsletterModal } from '@composite';
import { WithFsType } from '@types';
import { finnoscoreDataStyles as styles } from '../finnoscore-data/index.styles';
import { FinnoscoreListSection, FinnoscoreListSectionData, FinnoscoreListSectionMeta } from './FinnoscoreListSection';

export interface Props extends FinnoscoreListSectionMeta {
  pageContext: FinnoscoreListSectionData & WithFsType;
}

const FinnoScoreListPage = ({ isLoading, error, pageContext }: Props) => {
  const { countries, institutions, fsType } = pageContext;

  return (
    <React.Fragment>
      <Layout sx={styles.main}>
        <StaticSEO pageId={`finnoscore-list-${fsType}`} />
        <FinnoscoreListSection fsType={fsType} countries={countries} institutions={institutions} isLoading={isLoading} error={error} />
      </Layout>
      <NewsletterModal />
    </React.Fragment>
  );
};

export default FinnoScoreListPage;
