import React from 'react';
import is from 'is_js';
import { isAllCountries } from './formatting';

export function isTouchDevice() {
  // return is.touchDevice; // not working, has to use ours
  if (typeof window === 'undefined') return true;
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (query: string) => window.matchMedia(query).matches;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if ('ontouchstart' in window || (window?.DocumentTouch && document instanceof DocumentTouch)) {
    return true;
  }
  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

export function isIE() {
  // TODO: remove , it was just for debugging!
  // if (process.env.NODE_ENV === 'development') return true;
  return is.ie();
}

export function isSafari() {
  return is.safari();
}

export function isLegacy() {
  const retvalue =
    !(
      is.iphone() // iphones, even old ones, seems to be stronger
    ) &&
    (isIE() ||
      is.windowsPhone() ||
      // TODO: evaluate if we need to consider version matching for older phones: https://github.com/bestiejs/platform.js/
      window?.matchMedia('(max-width: 350px)').matches ||
      window?.matchMedia('(max-height: 400px)').matches);
  return retvalue;
}

// hooks:
export function useIsTouchDevice(defvalue = false): boolean {
  const [touch, setTouch] = React.useState(defvalue);
  React.useEffect(() => {
    setTouch(isTouchDevice());
    if (process.env.NODE_ENV === 'development') {
      window.addEventListener('resize', () => {
        setTouch(isTouchDevice());
      });
    }
  }, []);
  return touch;
}

export function useIsLegacy() {
  const [legacy, setLegacy] = React.useState(false);
  React.useLayoutEffect(() => setLegacy(isLegacy()), []);
  return legacy;
}

interface GetDateParamProps {
  date?: string | null;
  compareDate?: string | null;
  clientCode?: string | null;
}

function isValidDate(dateString: string) {
  const d = new Date(dateString);
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d as unknown as number);
}

const shortenedDateLength = 5;

export const completeTimeString = (short: string) => {
  const timeString = `1${+short * 10 ** (12 - shortenedDateLength)}`;
  const completeDate = new Date(+timeString).toISOString().substring(0, 10);
  if (!isValidDate(completeDate)) throw new Error(`unable to parse ${short}`);
  return completeDate;
};

export function decodeDateFromClient(clientCode?: string | null): { date: string; compareDate: string } | undefined {
  const matches = clientCode?.match(new RegExp(`^\\w+.*(?<date>\\d{${shortenedDateLength}})(?<compareDate>\\d{${shortenedDateLength}})$`, 'i'));

  try {
    if (matches?.groups) {
      const { date, compareDate } = matches?.groups;
      const dates = {
        date: completeTimeString(date),
        compareDate: completeTimeString(compareDate),
      };
      // console.log('successfully decoded dates ', dates);
      return dates;
    }
  } catch (error) {
    console.warn('decodeDateFromClient error:', error);
  }
  return undefined;
}

export const getDateParam = ({ date, compareDate, clientCode }: GetDateParamProps) => ({
  date: decodeDateFromClient(clientCode)?.date || date || undefined,
  compareDate: decodeDateFromClient(clientCode)?.compareDate || compareDate || undefined,
  link: (prefix = '&') => {
    if (clientCode && decodeDateFromClient(clientCode)) return `${prefix}clientCode=${clientCode}`;
    if (date) return `${prefix}date=${date}${compareDate ? `&compareDate=${compareDate}` : ''}`;
    return '';
  },
});

// TODO: this solution might be refactored with the changes to getContentTypeUrl https://trello.com/c/2i8EHdPo/437-refactor-getcontenttypeurl
export function getParentFolder(pathname: string, search?: string) {
  const searchParams = new URLSearchParams(search);
  const date = searchParams.get('date');
  const compareDate = searchParams.get('compareDate');
  const clientCode = searchParams.get('clientCode');

  const countryCodeValue = searchParams.get('countryCode');
  const getCountryCode = (prefix: string) => (!isAllCountries(countryCodeValue) ? `${prefix}countryCode=${countryCodeValue}` : '');
  const countryCode = getCountryCode('?');
  const dateParam = getDateParam({ date, compareDate, clientCode }).link('?').concat(getCountryCode('&'));

  const parentMapper: { [key: string]: string } = {
    '/banks': `/de/banks${countryCode}`,
    '/en/banks': `/en/banks${countryCode}`,
    '/de/banks': `/de/banks${countryCode}`,
    '/fr/banks': `/fr/banks${countryCode}`,
    '/insurances': `/de/insurances${countryCode}`,
    '/en/insurances': `/en/insurances${countryCode}`,
    '/de/insurances': `/de/insurances${countryCode}`,
    '/fr/insurances': `/fr/insurances${countryCode}`,
    '/en/case-study': '/en/case-studies',
    '/de/case-study': '/de/case-studies',
    '/fr/case-study': '/fr/case-studies',
  };
  const pathMapper: { [key: string]: string } = {
    '/banks': `/de/banks${countryCode}`,
    '/en/banks': `/en/banks${countryCode}`,
    '/de/banks': `/de/banks${countryCode}`,
    '/fr/banks': `/fr/banks${countryCode}`,
    '/en/banks/compare-preview': `/en/banks-preview${dateParam}`,
    '/de/banks/compare-preview': `/de/banks-preview${dateParam}`,
    '/fr/banks/compare-preview': `/fr/banks-preview${dateParam}`,
    '/insurances': `/de/insurances${countryCode}`,
    '/en/insurances': `/en/insurances${countryCode}`,
    '/de/insurances': `/de/insurances${countryCode}`,
    '/fr/insurances': `/fr/insurances${countryCode}`,
    '/en/insurances/compare-preview': `/en/insurances-preview${dateParam}`,
    '/de/insurances/compare-preview': `/de/insurances-preview${dateParam}`,
    '/fr/insurances/compare-preview': `/fr/insurances-preview${dateParam}`,
  };

  const path = pathname.replace(/\/+$/, '');
  const paths = path.split('/');
  const parent = paths.slice(0, paths.length - 1).join('/');

  return pathMapper[path] || parentMapper[parent] || parent;
}
