import { useLocation } from '@reach/router';
import { Institution, CountryCalculationType, ComparisonSubjectType, FsType, FS_TYPES } from '@types';
import { getParentFolder } from '@modules';
import { ControlItem } from '@core';
import { useTranslate } from '../../i18n';
import { useComparisonLink } from './useComparisonLink';

export interface Option extends Required<ControlItem> {}

export const usePathnameSearchOptionList = (pathname: string, search?: string, comparisonLink?: string): Option[] => {
  const __ = useTranslate();
  return [
    {
      title: __('common.ranking'),
      value: getParentFolder(pathname, search),
    },
    {
      title: __('common.compare2'),
      value: comparisonLink || `${pathname}${search}`,
    },
  ];
};

export const useDefaultOptionList = (comparisonLink?: string): Option[] => {
  const { pathname, search } = useLocation();
  return usePathnameSearchOptionList(pathname, search, comparisonLink);
};

const getDefaultAverageLinkForFsType = (topInstitution?: Institution, fsType?: FsType) => {
  if (!topInstitution) return 'fs_country_calculations.ALLE.average';

  const { country } = topInstitution;
  if (!country?.countryCode) return 'fs_country_calculations.ALLE.average';

  const isMiddleEastBankType = fsType === FS_TYPES[1];
  const isBankOrPrivateBankType = fsType !== FS_TYPES[2];

  // Return country-based calculation if not Middle East option and is a bank type
  if (isBankOrPrivateBankType && !isMiddleEastBankType) {
    return `${ComparisonSubjectType.CountryCalculation}.${country.countryCode}.${CountryCalculationType.Average}`;
  }

  return 'fs_country_calculations.ALLE.average';
};

export const useInstitutionOptionList = (topInstitution?: Institution, fsType?: FsType) => {
  const defaultAverageLink = getDefaultAverageLinkForFsType(topInstitution, fsType);

  const comparisonLink = useComparisonLink(
    `${defaultAverageLink}`,
    `${ComparisonSubjectType.Institution}.${topInstitution?.country.countryCode}.${topInstitution?.name}`,
    topInstitution?.fsType || 'bank'
  );

  const defaultCountryOptionList = useDefaultOptionList(comparisonLink);
  return defaultCountryOptionList;
};
