import React, { useState, createContext } from 'react';
import { RGBColor } from 'react-color';
import { themeConfig as theme } from '@themed';
import { updateAtIndex } from '@modules';

export type ColorContextProps = [RGBColor[], (colors: RGBColor[]) => void];

export const ColorContext = createContext<ColorContextProps>(null as unknown as ColorContextProps);

interface ColorContextProviderProps {
  defaultColors?: RGBColor[];
  children: React.ReactNode;
}

export const ColorContextProvider = ({ children, defaultColors: defaultColor }: ColorContextProviderProps) => {
  const [colors, setColors] = useState<RGBColor[]>(defaultColor || [theme.primaryRgbaObject]);
  return <ColorContext.Provider value={[colors, setColors]}>{children}</ColorContext.Provider>;
};

// Hook to make sure we wrapped our components into a ColorContext
export const useColors = () => {
  if (!ColorContext) throw Error('Has to be nested in <ColorContextProvider>');
  const [colors, setColors] = React.useContext(ColorContext);
  const setColorAtIndex = (newColor: RGBColor, index: number) => setColors(updateAtIndex(colors, newColor, index));
  return [colors, setColors, setColorAtIndex] as const;
};

export const isColorLighter = ({ r, g, b }: RGBColor) => {
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5;
};

export const useInvertedThemeColorValues = () => {
  const [colors] = useColors();

  return colors.map((color) => (isColorLighter(color) ? 'text' : 'background'));
};

export const useColorAtIndex = (index: number) => {
  if (!ColorContext) throw Error('Has to be nested in <ColorContextProvider>');
  const [colors, setColors] = React.useContext(ColorContext);
  const setColor = (newColor: RGBColor) => setColors([...colors.slice(0, index), newColor, ...colors.slice(index + 1)]);
  return [colors[index], setColor] as const;
};

export const useColor = () => useColorAtIndex(0);

// Helper function for determine wheter its a single color or multiple colors in an array
export const getBarColor = (index: number, hookColor: RGBColor[]): RGBColor => {
  if (Array.isArray(hookColor)) {
    return hookColor[index];
  }
  return hookColor;
};
