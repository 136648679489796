import { Dimensions } from './Dimension';
import { Image } from './Article';
import { Country, CountryFromApi } from './Country';
import { Translation } from './Translation';

export enum ComparisonSubjectType {
  CountryCalculation = 'fs_country_calculations',
  Institution = 'fs_institutions',
}

export const AllComparisonSubjectTypes = [ComparisonSubjectType.CountryCalculation, ComparisonSubjectType.Institution];

export interface ComparisonSubjectBase {
  id: string | number;
  dimensions: Dimensions;
  total: number;
  rank?: number;
  type?: string;
}

export interface ComparisonSubjectFromApi extends ComparisonSubjectBase {
  name?: string;
  logo?: Image;
  country?: CountryFromApi;
}

export interface ComparisonFromApi {
  a: ComparisonSubjectFromApi[];
  b: ComparisonSubjectFromApi[];
  translations: Translation[];
}

export interface ComparisonSubject extends ComparisonSubjectBase {
  name: string;
  image?: Image;
  winner?: boolean;
  country?: Country;
}

export interface Comparison {
  a: ComparisonSubject;
  b: ComparisonSubject;
}
