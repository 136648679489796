/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Layout, StaticSEO } from '@core';
import { SxStyles, WithFsType } from '@types';
import { NewsletterModal } from '@composite';
import { useSelectionBasedOnFsType, FinnoscoreComparisonSectionData } from '@modules';
import { finnoscoreDataStyles } from '../finnoscore-data/index.styles';
import { ColorContextProvider } from '../colorpicker';
import { FinnoscoreComparisonSection, FinnosoreComparisonSectionMeta } from './FinnoscoreComparisonSection';

const styles: SxStyles = {
  main: finnoscoreDataStyles.main,
};

export interface PageContextProps extends FinnosoreComparisonSectionMeta {
  pageContext: FinnoscoreComparisonSectionData & WithFsType;
}

const FinnoscoreComparisonPage = ({ isLoading, error, pageContext }: PageContextProps) => {
  const { countries, institutions, countryCalculations, fsType } = useSelectionBasedOnFsType(pageContext);

  return (
    <React.Fragment>
      <Layout sx={styles.main}>
        <StaticSEO pageId={`finnoscore-comparison-${fsType}`} />
        <ColorContextProvider>
          <FinnoscoreComparisonSection
            isLoading={isLoading}
            error={error}
            countries={countries}
            institutions={institutions}
            countryCalculations={countryCalculations}
          />
        </ColorContextProvider>
      </Layout>
      <NewsletterModal />
    </React.Fragment>
  );
};

export default FinnoscoreComparisonPage;
