import React from 'react';

export const isExternalLink = (link: string) => {
  if (link.includes('http')) {
    return true;
  }
  if (link[0] === '/') {
    return false;
  }
  // TODO: more conditions?
  return false;
};

export function isInternalLink(url?: string) {
  if (!url) {
    return false;
  }

  try {
    const link = new URL(url, window.location.origin);
    const currentHost = window.location.hostname;

    return link.hostname === currentHost;
  } catch (e) {
    console.warn('Invalid URL:', url);
    return false;
  }
}

export const useLocation = (dependencies?: unknown[]) => {
  const [path, setPath] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setPath(window.location.href);
  }, dependencies || []);

  return path;
};

export const useQueryParams = (dependencies?: unknown[]) => {
  const path = useLocation(dependencies);
  if (!path) return undefined;

  const splitPath = path?.split('?');
  if (!splitPath || splitPath.length <= 1) return undefined;

  const queryString = splitPath[1];
  const queryParamStrings = queryString.split('&');

  const params = queryParamStrings.reduce(
    (accum, current) => {
      const splitStr = current.split('=');
      if (!splitStr || splitStr.length <= 1) return accum;
      const key = splitStr[0];
      const value = splitStr[1];
      return { ...accum, [key]: value };
    },
    {} as { [key: string]: string }
  );

  return params;
};

export const formatSEOLink = (to?: string, onEmpty = '') => {
  const separators = ['', '?', '&'];
  const formatSEOLinkFragment = (lf: string, index: number) => (index === 0 && !to?.match(/^\?.*/) ? `${lf}${!lf.match(/\/$/) ? '/' : ''}` : lf);
  return to
    ? to
        ?.split('?')
        ?.map(formatSEOLinkFragment)
        .reduce(
          (link, current, index) => `${link}${separators[index] !== undefined ? separators[index] : separators[separators.length - 1]}${current}`,
          ''
        )
    : onEmpty;
};
